<template>
    <PrimaryButton class="scale-125 origin-left"
                   @click="scrollToDemoCallSection"
    >
        Try a free test conversation
    </PrimaryButton>
</template>
<script setup>
import PrimaryButton from "@/Components/PrimaryButton.vue";

const getOffsetTop = element => {
    let offsetTop = 0;
    while(element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
}

const scrollToDemoCallSection = () => {
    const top = getOffsetTop(document.querySelector('#democall'))
    window.scrollTo({ top, behavior: 'smooth' })
}
</script>
